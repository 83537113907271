<template>
  <b-modal
    id="booststrapmodal"
    centered
    :visible="visible"
    :hide-header-close="true"
    class="text-align-center"
    size="lg"
    :title="
      T(
        'Web.BackgroundDataPage.EWCCodes.DefaultCOSNumbersImportModal.Title',
        'Select EWC-code default CO2 numbers to import'
      )
    "
  >
    <page-loader :loading="isLoading">
      <div>
        <b-alert class="p-1" variant="warning" show>
          <feather-icon icon="AlertCircleIcon" size="20" class="mr-2" />
          {{
            T(
              "Web.BackgroundDataPage.EWCCodes.DefaultCOSNumbersImportModal.Disclaimer",
              "Disclaimer"
            )
          }}
        </b-alert>
        <div class="d-flex justify-content-end mb-1">
          <b-button
            variant="flat-secondary"
            size="sm"
            @click="toggleSelectDeselectAll"
          >
            {{ T("Web.Generic.SelectDeselectAll") }}
          </b-button>
        </div>
        <b-overlay :show="submitting">
          <b-table
            small
            :fields="tableFields"
            :items="defaults"
            :tbody-tr-class="rowClass"
          >
            <template #cell(action)="data">
              <b-form-checkbox
                @change="toggleSelectedDefault(data.item)"
                :checked="selectedDefaults.includes(data.item)"
              />
            </template>
          </b-table>
        </b-overlay>
      </div>
    </page-loader>
    <template #modal-footer>
      <b-button
        variant="light"
        class="float-right"
        @click="handleReject"
        :disabled="submitting"
      >
        {{ T("Web.Generic.Cancel") }}
      </b-button>
      <b-button
        variant="primary"
        class="float-right"
        @click="handleAccept"
        :disabled="selectedDefaults.length == 0 || submitting"
      >
        {{
          T(
            "Web.BackgroundDataPage.EWCCodes.DefaultCOSNumbersImportModal.Import",
            "Import"
          )
        }}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
  BTable,
  BFormCheckbox,
  BAlert,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import ResourceService from "@/services/base/resource.service";
import { mapActions } from "vuex";
export default {
  components: {
    BFormCheckbox,
    BOverlay,
    BModal,
    BTable,
    BAlert,
    BButton,
    PageLoader,
  },
  data() {
    return {
      submitting: false,
      visible: false,
      isLoading: true,
      defaults: [],
      selectedDefaults: [],
      resolvePromise: undefined,
      rejectPromise: undefined,
      tableFields: [
        {
          key: "code",
          label: this.T("Web.Generic.EWCCodes.Code"),
        },
        {
          key: "name",
          label: this.T("Web.Generic.EWCCodes.Name", "Name"),
        },
        {
          key: "landfillEmissions",
          label: this.T("Web.Generic.EWCCodes.LandfillEmissions"),
        },
        {
          key: "productionEmissions",
          label: this.T("Web.Generic.EWCCodes.ProductionEmissions"),
        },
        {
          key: "recycleEmissions",
          label: this.T("Web.Generic.EWCCodes.RecycleEmissions"),
        },
        {
          key: "reuseEmissions",
          label: this.T("Web.Generic.EWCCodes.ReuseEmissions"),
        },
        {
          key: "combustionEmissions",
          label: this.T("Web.Generic.EWCCodes.CombustionEmissions"),
        },
        {
          key: "action",
          label: this.T("Web.Generic.Select"),
        },
      ],
    };
  },
  methods: {
    ...mapActions({ putewc: "ewcs/put" }),
    async open() {
      this.visible = true;
      const service = new ResourceService("ewcs");
      this.defaults = (await service.get("defaults")).data.data;
      this.isLoading = false;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    toggleSelectedDefault(item) {
      if (this.selectedDefaults.includes(item)) {
        const indexToRemove = this.selectedDefaults.indexOf(
          this.selectedDefaults.find((i) => i.id == item.id)
        );
        this.selectedDefaults.splice(indexToRemove, 1);
      } else {
        this.selectedDefaults.push(item);
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (this.selectedDefaults.includes(item)) {
        return "table-primary";
      }
    },
    handleReject() {
      this.selectedDefaults = [];
      this.visible = false;
      this.resolvePromise(false);
    },
    async handleAccept() {
      for (const item of this.selectedDefaults) {
        item.alias = item.name;
        const service = new ResourceService("ewcs");
        await service.put(`${item.id}`, item);
      }
      this.selectedDefaults = [];
      this.visible = false;
      this.resolvePromise(true);
    },
    toggleSelectDeselectAll() {
      if (this.selectedDefaults.length > 0) {
        this.selectedDefaults = [];
      } else {
        this.defaults.forEach((item) => this.selectedDefaults.push(item));
      }
    },
  },
};
</script>