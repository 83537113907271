<template>
  <div>
    <loader class="loader" ref="loader"></loader>
    <b-table
      responsive
      :fields="fields"
      :items="orderEwcList"
      primary-key="code"
      sticky-header
      class="ewc-table"
    >
      <template #cell(code)="data">
        <span
          class="font-weight-bold text-danger d-flex align-items-center"
          v-if="data.item.hazardousWaste"
        >
          {{ data.item.code }}
          <feather-icon
            class="text-danger ml-1"
            icon="AlertTriangleIcon"
            size="16"
            v-b-tooltip.hover.left="
              T('Web.BackgroundDataPage.EWCCodes.ShowHazardousWaste')
            "
          />
        </span>
        <span class="font-weight-bold text-primary" v-else>{{
          data.item.code
        }}</span>
      </template>
      <template #cell(name)="data">
        <div style="max-width: 400px">
          <small>{{ data.item.name }}</small>
          <b-form-input
            @change="updateEwc(data.item)"
            type="text"
            class="form-control form-control form-control-sm"
            v-model="data.item.alias"
            size="sm"
            :placeholder="T('Web.BackgroundDataPage.EWCCodes.Alias', 'Alias')"
          />
        </div>
      </template>
      <template #cell(landfillEmissions)="data">
        <span class="d-flex justify-content-center align-items-center">
          <b-form-input
            @change="updateEwc(data.item)"
            type="number"
            class="form-control"
            v-model="data.item.landfillEmissions"
            size="sm"
          /><feather-icon
            icon="InfoIcon"
            class="ml-1"
            v-b-tooltip.hover.top="
              T('Web.Generic.Units.TonsPrTons', 'Tons pr tons')
            "
          />
        </span>
      </template>
      <template #cell(productionEmissions)="data">
        <span class="d-flex justify-content-center align-items-center">
          <b-form-input
            @change="updateEwc(data.item)"
            type="number"
            class="form-control"
            v-model="data.item.productionEmissions"
            size="sm"
          /><feather-icon
            icon="InfoIcon"
            class="ml-1"
            v-b-tooltip.hover.top="
              T('Web.Generic.Units.TonsPrTons', 'Tons pr tons')
            "
          />
        </span>
      </template>
      <template #cell(recycleEmissions)="data">
        <span class="d-flex justify-content-center align-items-center">
          <b-form-input
            @change="updateEwc(data.item)"
            type="number"
            class="form-control"
            v-model="data.item.recycleEmissions"
            size="sm"
          /><feather-icon
            icon="InfoIcon"
            class="ml-1"
            v-b-tooltip.hover.top="
              T('Web.Generic.Units.TonsPrTons', 'Tons pr tons')
            "
          />
        </span>
      </template>
      <template #cell(reuseEmissions)="data">
        <span class="d-flex justify-content-center align-items-center">
          <b-form-input
            @change="updateEwc(data.item)"
            type="number"
            class="form-control"
            v-model="data.item.reuseEmissions"
            size="sm"
          /><feather-icon
            icon="InfoIcon"
            class="ml-1"
            v-b-tooltip.hover.top="
              T('Web.Generic.Units.TonsPrTons', 'Tons pr tons')
            "
          />
        </span>
      </template>
      <template #cell(combustionEmissions)="data">
        <span class="d-flex justify-content-center align-items-center">
          <b-form-input
            @change="updateEwc(data.item)"
            type="number"
            class="form-control"
            v-model="data.item.combustionEmissions"
            size="sm"
          /><feather-icon
            icon="InfoIcon"
            class="ml-1"
            v-b-tooltip.hover.top="
              T('Web.Generic.Units.TonsPrTons', 'Tons pr tons')
            "
          />
        </span>
      </template>
    </b-table>
  </div>
</template>
<script>
import { BTable, BFormInput, VBTooltip } from "bootstrap-vue";
import loader from "@/components/layout/loader.vue";
import { mapActions } from "vuex";
export default {
  components: {
    BTable,
    loader,
    BFormInput,
  },
  props: {
    ewcs: {
      required: true,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      orderEwcList: [],
      fields: [
        {
          key: "code",
          label: this.T("Web.Generic.EWCCodes.Code"),
          sortable: true,
        },
        {
          key: "name",
          label: this.T("Web.Generic.EWCCodes.Name", "Name"),
          sortable: true,
        },
        {
          key: "landfillEmissions",
          label: this.T("Web.Generic.EWCCodes.LandfillEmissions"),
          sortable: true,
        },
        {
          key: "productionEmissions",
          label: this.T("Web.Generic.EWCCodes.ProductionEmissions"),
          sortable: true,
        },
        {
          key: "recycleEmissions",
          label: this.T("Web.Generic.EWCCodes.RecycleEmissions"),
          sortable: true,
        },
        {
          key: "reuseEmissions",
          label: this.T("Web.Generic.EWCCodes.ReuseEmissions"),
          sortable: true,
        },
        {
          key: "combustionEmissions",
          label: this.T("Web.Generic.EWCCodes.CombustionEmissions"),
          sortable: true,
        },
      ],
    };
  },
  created() {
    this.orderEwcList = this.ewcs.sort((a, b) => {
      if (b.alias == null && a.alias == null) return 0;
      if (b.alias == null && a.alias != null) return -1;
      if (b.alias != null && a.alias == null) return 1;
    });
  },
  methods: {
    ...mapActions({ fetchewcs: "ewcs/fetchList", putewc: "ewcs/put" }),
    async updateEwc(ewc) {
      this.$refs.loader.loading();
      await this.putewc({ data: ewc });
      this.$refs.loader.loadCompelete();
    },
  },
  computed: {
    // orderEwcList() {
    //   return this.ewcs.sort((a, b) => {
    //     if (b.alias == null && a.alias == null) return 0;
    //     if (b.alias == null && a.alias != null) return -1;
    //     if (b.alias != null && a.alias == null) return 1;
    //   });
    // },
  },
};
</script>
<style lang="scss" scoped>
.loader {
  position: absolute;
  top: 19px;
  right: 22px;
}
.ewc-table {
  max-height: calc(100vh - 350px);
}
</style>