var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader',{ref:"loader",staticClass:"loader"}),_c('b-table',{staticClass:"ewc-table",attrs:{"responsive":"","fields":_vm.fields,"items":_vm.orderEwcList,"primary-key":"code","sticky-header":""},scopedSlots:_vm._u([{key:"cell(code)",fn:function(data){return [(data.item.hazardousWaste)?_c('span',{staticClass:"font-weight-bold text-danger d-flex align-items-center"},[_vm._v(" "+_vm._s(data.item.code)+" "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(
            _vm.T('Web.BackgroundDataPage.EWCCodes.ShowHazardousWaste')
          ),expression:"\n            T('Web.BackgroundDataPage.EWCCodes.ShowHazardousWaste')\n          ",modifiers:{"hover":true,"left":true}}],staticClass:"text-danger ml-1",attrs:{"icon":"AlertTriangleIcon","size":"16"}})],1):_c('span',{staticClass:"font-weight-bold text-primary"},[_vm._v(_vm._s(data.item.code))])]}},{key:"cell(name)",fn:function(data){return [_c('div',{staticStyle:{"max-width":"400px"}},[_c('small',[_vm._v(_vm._s(data.item.name))]),_c('b-form-input',{staticClass:"form-control form-control form-control-sm",attrs:{"type":"text","size":"sm","placeholder":_vm.T('Web.BackgroundDataPage.EWCCodes.Alias', 'Alias')},on:{"change":function($event){return _vm.updateEwc(data.item)}},model:{value:(data.item.alias),callback:function ($$v) {_vm.$set(data.item, "alias", $$v)},expression:"data.item.alias"}})],1)]}},{key:"cell(landfillEmissions)",fn:function(data){return [_c('span',{staticClass:"d-flex justify-content-center align-items-center"},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"number","size":"sm"},on:{"change":function($event){return _vm.updateEwc(data.item)}},model:{value:(data.item.landfillEmissions),callback:function ($$v) {_vm.$set(data.item, "landfillEmissions", $$v)},expression:"data.item.landfillEmissions"}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
            _vm.T('Web.Generic.Units.TonsPrTons', 'Tons pr tons')
          ),expression:"\n            T('Web.Generic.Units.TonsPrTons', 'Tons pr tons')\n          ",modifiers:{"hover":true,"top":true}}],staticClass:"ml-1",attrs:{"icon":"InfoIcon"}})],1)]}},{key:"cell(productionEmissions)",fn:function(data){return [_c('span',{staticClass:"d-flex justify-content-center align-items-center"},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"number","size":"sm"},on:{"change":function($event){return _vm.updateEwc(data.item)}},model:{value:(data.item.productionEmissions),callback:function ($$v) {_vm.$set(data.item, "productionEmissions", $$v)},expression:"data.item.productionEmissions"}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
            _vm.T('Web.Generic.Units.TonsPrTons', 'Tons pr tons')
          ),expression:"\n            T('Web.Generic.Units.TonsPrTons', 'Tons pr tons')\n          ",modifiers:{"hover":true,"top":true}}],staticClass:"ml-1",attrs:{"icon":"InfoIcon"}})],1)]}},{key:"cell(recycleEmissions)",fn:function(data){return [_c('span',{staticClass:"d-flex justify-content-center align-items-center"},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"number","size":"sm"},on:{"change":function($event){return _vm.updateEwc(data.item)}},model:{value:(data.item.recycleEmissions),callback:function ($$v) {_vm.$set(data.item, "recycleEmissions", $$v)},expression:"data.item.recycleEmissions"}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
            _vm.T('Web.Generic.Units.TonsPrTons', 'Tons pr tons')
          ),expression:"\n            T('Web.Generic.Units.TonsPrTons', 'Tons pr tons')\n          ",modifiers:{"hover":true,"top":true}}],staticClass:"ml-1",attrs:{"icon":"InfoIcon"}})],1)]}},{key:"cell(reuseEmissions)",fn:function(data){return [_c('span',{staticClass:"d-flex justify-content-center align-items-center"},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"number","size":"sm"},on:{"change":function($event){return _vm.updateEwc(data.item)}},model:{value:(data.item.reuseEmissions),callback:function ($$v) {_vm.$set(data.item, "reuseEmissions", $$v)},expression:"data.item.reuseEmissions"}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
            _vm.T('Web.Generic.Units.TonsPrTons', 'Tons pr tons')
          ),expression:"\n            T('Web.Generic.Units.TonsPrTons', 'Tons pr tons')\n          ",modifiers:{"hover":true,"top":true}}],staticClass:"ml-1",attrs:{"icon":"InfoIcon"}})],1)]}},{key:"cell(combustionEmissions)",fn:function(data){return [_c('span',{staticClass:"d-flex justify-content-center align-items-center"},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"number","size":"sm"},on:{"change":function($event){return _vm.updateEwc(data.item)}},model:{value:(data.item.combustionEmissions),callback:function ($$v) {_vm.$set(data.item, "combustionEmissions", $$v)},expression:"data.item.combustionEmissions"}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
            _vm.T('Web.Generic.Units.TonsPrTons', 'Tons pr tons')
          ),expression:"\n            T('Web.Generic.Units.TonsPrTons', 'Tons pr tons')\n          ",modifiers:{"hover":true,"top":true}}],staticClass:"ml-1",attrs:{"icon":"InfoIcon"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }