<template>
  <div>
    <ewc-defaults-import-modal ref="ewcDefaultImportModal" />
    <transition name="fade" mode="out-in">
      <b-card no-body v-if="loading">
        <div class="d-flex justify-content-center mt-2 mb-2">
          <b-spinner />
        </div>
      </b-card>
      <div class="accordion" role="tablist" v-else>
        <b-card no-body class="header p-2">
          <div class="d-flex align-items-center justify-content-between">
            <input
              type="text"
              class="form-control col-md-4"
              v-model="search"
              :placeholder="T('Web.Generic.Search', 'Search')"
            />
            <div class="col-md-5 d-flex justify-content-end align-items-center">
              <b-button
                size="sm"
                class="mr-1"
                @click="openEWCDefaultImportModal"
                >{{
                  T(
                    "Web.BackgroundDataPage.EWCCodes.ImportDefaultCO2Numbers",
                    "Select default CO2 numbers to import"
                  )
                }}
                <feather-icon icon="DownloadIcon" />
              </b-button>

              <b-form-checkbox value="true" v-model="showHazardousWaste">{{
                T(
                  "Web.BackgroundDataPage.EWCCodes.ShowHazardousWaste",
                  "Show hazardous waste"
                )
              }}</b-form-checkbox>
            </div>
          </div>
        </b-card>

        <b-card no-body class="mb-1" v-if="search == ''">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle="'1337'"
              variant="light"
              class="text-left"
            >
              {{
                T(
                  "Web.BackgroundDataPage.EWCCodes.FilledEWCCodes",
                  "Filled EWC Codes"
                )
              }}</b-button
            >
          </b-card-header>
          <b-collapse
            id="1337"
            accordion="my-accordion"
            role="tabpanel"
            @show="activeGroup = '1337'"
            :visible="activeGroup == '1337'"
          >
            <b-card-body>
              <ewc-group-table
                :ewcs="filledEwcs"
                v-if="activeGroup == '1337'"
              />
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card
          no-body
          class="mb-1"
          v-for="group in ewcGroupList"
          :key="group.id"
        >
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle="group.id"
              variant="light"
              class="text-left"
              >{{ group.label }}</b-button
            >
          </b-card-header>
          <b-collapse
            :id="group.id"
            accordion="my-accordion"
            role="tabpanel"
            @show="activeGroup = group.id"
            :visible="activeGroup == group.id"
          >
            <b-card-body>
              <ewc-group-table
                :ewcs="group.children"
                v-if="activeGroup == group.id"
              />
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import loader from "@/components/layout/loader.vue";
import EwcGroupTable from "./ewc/EwcGroupTable.vue";
import {
  BSpinner,
  BCard,
  BButton,
  BCardHeader,
  BCollapse,
  BCardBody,
  VBToggle,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import EwcDefaultsImportModal from "./ewc/EwcDefaultsImportModal.vue";
export default {
  components: {
    BSpinner,
    BCard,
    BButton,
    loader,
    BCardHeader,
    BCollapse,
    BCardBody,
    VBToggle,
    EwcGroupTable,
    BFormGroup,
    BFormCheckbox,
    EwcDefaultsImportModal,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  async created() {
    this.$store.dispatch(`ewcs/fetchList`);
  },
  data() {
    return {
      showHazardousWaste: false,
      currentPage: 1,
      search: "",
      itemsPrPage: 20,
      ewcTableFields: [
        {
          key: "code",
          label: this.T("Web.Generic.EWCCodes.Code", "Code"),
        },
        {
          key: "name",
          label: this.T("Web.Generic.Name", "Name"),
        },
        {
          key: "landfillEmissions",
          label: this.T(
            "Web.Generic.EWCCodes.LandfillEmissions",
            "Landfill emissions"
          ),
        },
        {
          key: "productionEmissions",
          label: this.T(
            "Web.Generic.EWCCodes.ProductionEmissions",
            "Production emissions"
          ),
        },
        {
          key: "recycleEmissions",
          label: this.T(
            "Web.Generic.EWCCodes.RecycleEmissions",
            "Recycle emissions"
          ),
        },
        {
          key: "reuseEmissions",
          label: this.T(
            "Web.Generic.EWCCodes.ReuseEmissions",
            "Reuse emissions"
          ),
        },
        {
          key: "combustionEmissions",
          label: this.T(
            "Web.Generic.EWCCodes.CombustionEmissions",
            "Combustion emissions"
          ),
        },
      ],
      openedGroups: [],
      activeGroup: null,
    };
  },
  computed: {
    ...mapGetters({ loading: "ewcs/isLoading", ewcsList: "ewcs/list" }),
    currentEwcList() {
      let startIndex =
        this.currentPage == 1 ? 0 : this.currentPage * this.itemsPrPage;
      return this.ewcs.slice(startIndex, startIndex + this.itemsPrPage);
    },
    ewcGroupList() {
      return this.ewcs.reduce((rv, code) => {
        (rv[code["categoryName"]] = rv[code["categoryName"]] || {
          id: this.stringToHash(code["categoryName"]).toString(),
          label: code["categoryName"],
          children: [],
        }).children.push(code);
        return rv;
      }, {});
    },
    searchTermSet() {
      return this.search != "";
    },
    ewcs() {
      let list = this.ewcsList;
      if (!this.showHazardousWaste)
        list = list.filter((c) => c.hazardousWaste == false);
      if (this.search == "") return list;
      return list.filter(
        (e) =>
          e.code.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          e.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          e.eCode.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          e.hCode.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      );
    },
    filledEwcs() {
      let list = this.ewcsList;
      list = list.filter(
        (e) =>
          e.alias != null ||
          e.landfillEmissions > 0 ||
          e.productionEmissions > 0 ||
          e.recycleEmissions > 0 ||
          e.reuseEmissions > 0 ||
          e.combustionEmissions > 0
      );
      if (!this.showHazardousWaste)
        list = list.filter((c) => c.hazardousWaste == false);
      if (this.search == "") return list;
      return list.filter(
        (e) =>
          e.code.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          e.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          e.eCode.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          e.hCode.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      );
    },
  },
  methods: {
    ...mapActions({ fetchewcs: "ewcs/fetchList", putewc: "ewcs/put" }),
    async openEWCDefaultImportModal() {
      const result = await this.$refs.ewcDefaultImportModal.open();
      if (result) {
        this.$store.dispatch(`ewcs/fetchList`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  position: relative;
  .loader {
    position: absolute;
    top: -15px;
    right: 0;
  }
}
.row {
  align-items: end;
}
.rowitem {
  &.offset-top {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 10px;
  }
}
.offset-top {
  margin-top: 20px;
}
</style>